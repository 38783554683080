<template>
    <div class="img-gallery">
        <a :href="image.path" target="_blank" v-for="image in images" :key="image.name">
            <img :src="image.path" :alt="image.name">
        </a>
    </div>
</template>
<script>
import images from '@/data/image-manifest.json'

export default {
    name: 'images',
    components: {},
    data: function () {
        return {
            images: images
        }
    }
}
</script>
<style>
.img-gallery img {
    display: block;
    max-width: 300px;
    width: 100%;
    height: auto;
}
.img-gallery a {
    display: block;
    margin-bottom: 1em;
}
.img-gallery {
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.img-gallery > a {
    width: 100%;
    max-width: 210px;
    padding: 5px;
    height: auto;
}
.img-gallery a {
    display: block;
    margin-bottom: 1em;
}
</style>